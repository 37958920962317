/* Style the smaller switch container */
.switch.small {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
  }
  
  /* Reduce the size of the slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 20px;
  }
  
  /* Reduce the size of the circle within the slider */
  .slider::before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
  
  /* Adjust the size of the slider and circle when the checkbox is checked */
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:checked + .slider::before {
    transform: translateX(16px);
  }
  