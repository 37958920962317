/* Cards */

.card {
    border: none;
    /* box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important; */
    .card-title {
      color: black;
      font-weight: 900;
      margin-bottom: 1.125rem;
      text-transform: capitalize;
    }
    .card-subtitle {
      /* @extend .text-gray; */
      font-weight: normal;
      margin-top: 0.625rem;
      margin-bottom: 0.625rem;
    
    }
    .card-description {
      /* color: $card-description-color;
      font-weight: $font-weight-light; */
    }
    &.card-outline-success {
          border: 1px solid theme-color("success");
      }
      &.card-outline-primary {
          border: 1px solid theme-color("primary");
      }
      &.card-outline-warning {
          border: 1px solid theme-color("warning");
      }
      &.card-outline-danger {
          border: 1px solid theme-color("danger");
      }
      &.card-rounded {
          /* @include border-radius(5px); */
      }
  
    &.card-faded {
      background: #b5b0b2;
      border-color: #b5b0b2;
    }
    &.card-circle-progress {
      color: white;
      text-align: center;
    }
    &.card-img-holder {
      position: relative;
      .card-img-absolute {
        position: absolute;
        top:0;
        right: 0;
        height: 100%;
      }
    }
    &.corona-gradient-card {
      background-image: linear-gradient(to left, #d41459, #911a6c);
      border-radius: 6px;
      .gradient-corona-img {
        height: 85px;
        @media (max-width: 576px) {
          height: auto;
          min-height: 50px;
          max-height: 85px;
        }
      }
      @media (max-width: 576px) {
       h4 {
         font-size: 0.75rem;
       }
      }
    }
  }
  
  
  
  